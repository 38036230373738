import React from "react";
import "./App.css";
import Header from "./components/Header";
import CustomCard from "./components/CustomCard";
import Box from "@mui/material/Box";
import sevice_data from "./images/undraw_All_the_data_re_hh4w.png";
import sevice_programming from "./images/undraw_Programming_re_kg9v.png";
import sevice_knowledge from "./images/undraw_sharing_knowledge_03vp.png";
import sevice_logistics from "./images/undraw_real_time_analytics_re_yliv.png";
import about_honda from "./images/MasahisaHonda.JPG";
import Grid from "@mui/material/Unstable_Grid2";
import CompanyTable from "./components/CompanyTable";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Footer from "./components/Footer";
import Container from "@mui/material/Container";

const sevice_data_introduction = `経験豊富なエンジニアが、壁打ちや開発プロセス設計含め一貫してご支援させて頂きます。`;
const sevice_programming_introduction = `現場目線で課題解決やご要望を最短で実現するご支援させて頂きます。`;
const sevice_knowledge_introduction = `IT/AIソリューションの導入に向けた選定や、基礎知識の教育活動など幅広にご支援させて頂きます。`;
const sevice_logistics_introduction = `IT/AIを活用した輸出入等の物流・商流最適化や、貿易のご支援をさせて頂きます。`;

const self_introduction = `2021年3月北九州市立大学大学院を卒業。
  在学中はインターンや業務委託を通してプログラミングを学び、複数のハッカソン等で受賞。
  NICT主催のSeckHack365では8人チームのリーダとして、ぼったくり対策アプリの開発に従事。
  卒業後はAIエンジニアとして事業会社に就職し、複数の開発リードなどを経験。`;

function App() {
  return (
    <div className="App">
      <Header />
      <Container fixed>
        <Box component="main">
          <h2 id="Vision/Mission">Vision/Mission</h2>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Vision
          </Typography>
          <Typography gutterBottom align="center" variant="h6" component="div">
            日本の技術をAIの側面から底上げし、国境を越えた企業が日本企業の技術を求める時代の創出に貢献する
          </Typography>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Mission
          </Typography>
          <Typography gutterBottom align="center" variant="h6" component="div">
            小さな規模から日常業務のAI活用機会の創出を支援し、成果に紐づくサポートを一貫して実施します
          </Typography>
          <div className="ServiceContent">
            <h2 id="Service">Service</h2>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid xs={4} sm={4} md={4}>
                <CustomCard
                  img_url={sevice_data}
                  title="①AI開発/データ分析"
                  text={sevice_data_introduction}
                />
              </Grid>
              <Grid xs={4} sm={4} md={4}>
                <CustomCard
                  img_url={sevice_programming}
                  title="②ソフトウェア開発"
                  text={sevice_programming_introduction}
                />
              </Grid>
              <Grid xs={4} sm={4} md={4}>
                <CustomCard
                  img_url={sevice_knowledge}
                  title="③IT導入支援"
                  text={sevice_knowledge_introduction}
                />
              </Grid>
              <Grid xs={4} sm={4} md={4}>
                <CustomCard
                  img_url={sevice_logistics}
                  title="④商社/物流DX"
                  text={sevice_logistics_introduction}
                />
              </Grid>
            </Grid>
          </div>
          {/* <div className="AboutContent">
            <h2 id="About">About</h2>
            <CustomCard
              img_url={about_honda}
              title="CEO 長野陸"
              text={self_introduction}
            />
          </div> */}
          <div className="CompanyContent">
            <h2 id="Company">Company</h2>
            <CompanyTable />
          </div>
          <div className="ContactContent">
            <h2 id="Contact">Contact</h2>
            <Typography gutterBottom variant="h6" component="div">
              サービスや採用、取材などについてのお問い合わせはこちらから
            </Typography>
            <Button
              variant="contained"
              href="mailto:info@eurustrek.com"
              sx={{ backgroundColor: "#2ca6e0" }}
            >
              メールする
            </Button>
          </div>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
