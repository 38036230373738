import * as React from "react";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import "./Footer.css";

export default function Footer(props) {
  return (
    <div className="FooterContainer">
      <Typography className="FooterText" variant="p" component="div">
        © 2023 EURUS TREK. All rights reserved.
      </Typography>
      {/* <Button href="#">セキュリテイポリシー</Button>
      <Button href="#">プライバシーポリシー</Button> */}
    </div>
  );
}
