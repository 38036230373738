import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("会社名", "株式会社EURUS TREK（EURUS TREK, Inc.）"),
  createData("設立", "2023年9月29日"),
  createData(
    "住所",
    "〒150-0002　東京都渋谷区渋谷2-19-15宮益坂ビルディング609"
  ),
  // createData("代表者名", "本田真久（Masahisa Honda）"),
  createData("代表者名", "濱健人（Kento Hama）"),
  createData("事業内容", "AI開発/データ分析、ソフトウェア開発、IT導入支援"),
  createData("従業員数", "5名（業務委託等含む）"),
  createData("資本金", "500,000円"),
];

export default function CompanyTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 350 }} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.calories}</TableCell>
              <TableCell align="left">{row.fat}</TableCell>
              <TableCell align="left">{row.carbs}</TableCell>
              <TableCell align="left">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
